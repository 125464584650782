import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import UserProfile from '../components/UserProfile.vue';


import CopyRight from '../components/Info/CopyRight.vue';
import AboutUs from '../components/Info/AboutUs.vue';
import WhatsNew from '../components/Info/WhatsNew.vue';
//import HashtagProfile from '../components/HashtagProfile.vue';

const routes = [
  {
    method: 'GET',
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    method: 'GET',
    path: '/user/:id',
    name: 'UserProfile',
    component: UserProfile,
    props: true,
  },



  { path: '/Copyright', component: CopyRight },
  { path: '/about-us', component: AboutUs },
  { path: '/whats-new', component: WhatsNew },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

