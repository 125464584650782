
<template>
  <div class="Base-Div">
    <div class="profile-user-info">
      <img
        :src="userProfilePicture"
        alt="User Picture"
        class="user-round-picture"
      />
      <div class="profile-name">{{ userProfileName }}</div>
    </div>
    <div class="profile-Hash-container">
        <div class="profile-Hash-border">
          <div
            v-for="(hashtag, index) in activeHashtags"
            :key="index"
            class="hashtag-item"
            @click="filterByHashtag(hashtag.text)"
            :class="{ 'selected-hashtag': selectedHashtag === hashtag.text }"
          >
              <span class="profile-hash-item">{{ hashtag.text }}</span>
          </div>
        </div>
      </div>

    <div
      class="video-user-container"
      v-for="(video, index) in filteredVideos"
      :key="index"
      :data-embed-url="video.embedURL"
      :href="`#${index}`"
    >

      <div class="video-container">
        <!-- Preview Video -->
        <video
          v-if="!video.embedPlaying"
          :src="video.previewURL"
          class="profile-responsive-video preview"
          @ended="playEmbedVideo(index)"
          @click="playEmbedVideo(index)"
          preload="none"
          muted
        ></video>

        <!-- Embed Video -->
        <iframe
          v-else
          :src="video.currentEmbedURL"
          frameborder="0"
          allowfullscreen
          class="profile-responsive-video embed"
          :data-index="index"
          allow="autoplay; encrypted-media"
          sandbox="allow-same-origin allow-scripts allow-forms"
          @load="removeAds"
          ref="videoIframe"
        ></iframe>
      </div>
      
      <div class="user-buttons-and-tags">

        <div class="interaction-buttons">
            <button class="thumbs-up" @click="toggleThumbsUp(index)">
              <i :class="{'fas fa-thumbs-up': true, active: isThumbsUpActive(index)}"></i>
            </button>
            <button class="thumbs-down" @click="toggleThumbsDown(index)">
              <i class="fas fa-thumbs-down"></i>
            </button>
            <div
              :class="{
                'thumbs-up-text': thumbsUpCounts[index] === 1,
                'neutral-text': thumbsUpCounts[index] === 0,
              }"
            >
            </div>
        </div>
        <div class="video-hashtags-container">
          <div class="video-hashtags-border">
            <span
              v-for="(hashtag, idx) in video.hashtags"
              :key="idx"
              class="video-hashtag-item"
                      >
              {{ hashtag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, nextTick, onUpdated, computed } from 'vue';

const BASE_URL = 'https://strapi.vulcano2k.com';

export default {
  name: 'UserProfile',
  props: ['id'],
  setup(props) {
    const userVideos = ref([]);
    const thumbsUpCounts = ref([]);
    const videoRef = ref(null);
    const userProfilePicture = ref('');
    const userProfileName = ref('');
    const activeHashtags = ref([]);
    let iframeObserver; // Variable for IntersectionObserver for embedded videos (iframes)
    const selectedHashtag = ref('');


    // Lifecycle hook that runs when the component is mounted
    onMounted(() => {      
      fetchUserData(); // Fetch user data when the component is mounted
    });

    onUpdated(async () => {
      await nextTick(); // Aguarda a atualização do DOM antes de reconfigurar
      ObserverforPauseEmbed(); // Reconfigura o observer para novos iframes
    });  


    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user-profiles`, {
          params: {
            filters: {
              ProfileName: {
                $eq: props.id,
              },
            },
            populate: {
              user_video_embeds: {
                populate: {
                  hashtags: true,
                  VideoPreview: true,
                },
              },
              ProfilePicture: true,
            },
          },
        });

        const profiles = response.data.data;

        if (profiles.length > 0) {
          const userProfile = profiles[0];
          userProfileName.value = userProfile.attributes?.ProfileName || '';
          userProfilePicture.value = getProfilePicture(userProfile) || '';

          userVideos.value = userProfile.attributes.user_video_embeds.data.map(
            (videoItem) => ({
              embedURL: videoItem.attributes?.embedURL,
              hashtags: getHashtags(videoItem),
              previewURL: getVideoPreviewURL(videoItem),
              embedPlaying: false,
              currentEmbedURL: '',
              alreadyIsEmbed: false,
            })
          ).sort(() => Math.random() - 0.5); // Shuffle videos
          
          thumbsUpCounts.value = userVideos.value.map(() => 0);

          // Extrair e configurar hashtags únicas para o perfil
          const allHashtags = userVideos.value.flatMap((video) => video.hashtags);
          const uniqueHashtags = [...new Set(allHashtags)];
          activeHashtags.value = uniqueHashtags.map((tag) => ({
            text: tag,
            url: `https://www.vulcano2k.com/hashtag/${encodeURIComponent(tag)}`,
          }));

          await nextTick();
          setupIntersectionObserver();
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
 
    const getProfilePicture = (userProfile) => {
      const profilePictureData =
        userProfile.attributes?.ProfilePicture?.data?.attributes;
      if (profilePictureData?.url) {

        return `${BASE_URL}${profilePictureData.url}`;
      }
      return '';
    };


    const getHashtags = (videoItem) => {
      return (
        videoItem.attributes?.hashtags?.data.map(
          (hashtag) => hashtag.attributes.HashCategorie
        ) || []
      );
    };

    const getVideoPreviewURL = (item) => {
      const videoPreviewData = item.attributes?.VideoPreview?.data;

      if (
        videoPreviewData &&
        Array.isArray(videoPreviewData) &&
        videoPreviewData.length > 0
      ) {
        const previewAttributes = videoPreviewData[0].attributes;
        const previewUrl = previewAttributes?.url;
        return `${BASE_URL}${previewUrl}`;
      }

      return null;
    };

    const toggleThumbsUp = (index) => {
      thumbsUpCounts.value[index] = thumbsUpCounts.value[index] === 0 ? 1 : 0;
    };

    const isThumbsUpActive = (index) => {
      return thumbsUpCounts.value[index] === 1;
    };

    let observer;

    const toggleThumbsDown = (index) => {
      const videoContainer = document.querySelectorAll(
        '.video-user-container'
      )[index];

      if (videoContainer) {
        setTimeout(() => {
          // Remove the video
          userVideos.value.splice(index, 1);
          thumbsUpCounts.value.splice(index, 1);

          // Wait for the DOM to update
          nextTick(() => {
            // Disconnect and reinitialize the observer for the remaining videos
            observer.disconnect();

            // Re-observe the remaining videos
            document.querySelectorAll('.profile-responsive-video').forEach((video, i) => {
              if (!userVideos.value[i].embedPlaying) {
                // Skip if the video is already playing
                observer.observe(video);
              }
            });

            // Optionally, scroll the next video into view to trigger the observer
            const nextVideo = document.querySelectorAll('.video-user-container')[
              index
            ];
            if (nextVideo) {
              nextVideo.scrollIntoView({ behavior: 'smooth' });
            }
          });
        }, 100);
      }
    };

    const filterByHashtag = (hashtag) => {
      if (selectedHashtag.value === hashtag) {
        // Se a hashtag clicada já está selecionada, desmarca
        selectedHashtag.value = '';
      } else {
        // Caso contrário, seleciona a nova hashtag
        selectedHashtag.value = hashtag;
      }

      // Reseta o estado de reprodução dos vídeos filtrados
      filteredVideos.value.forEach((video) => {
        video.embedPlaying = false;
        video.currentEmbedURL = '';
      });

      // Aguarda a atualização do DOM e reaplica os observadores
      nextTick(() => {
        setupIntersectionObserver();
        ObserverforPauseEmbed();
      });
    };


    const filteredVideos = computed(() => {
      if (!selectedHashtag.value) {
        // Nenhuma hashtag selecionada, retorna todos os vídeos
        return userVideos.value;
      } else {
        // Retorna apenas os vídeos que contêm a hashtag selecionada
        return userVideos.value.filter(video =>
          video.hashtags.includes(selectedHashtag.value)
        );
      }
    });

  // Function to set up Intersection Observers
  const setupIntersectionObserver = () => {
      // Observer for preview videos
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
            if (entry.isIntersecting) {
              // If the video is in the viewport
              if (video.paused) {                
                video.play(); // Play the video
              }
            } else {
              // If the video is out of the viewport
              video.pause(); // Pause the video
              video.currentTime = 0; // Reset to start
            }
          });
        },
        {
          threshold: [0], // Trigger when any part of the element is visible
        }
      );
      // Observe all preview videos
      document.querySelectorAll('.profile-responsive-video.preview').forEach((video) => {
        observer.observe(video);
      });
    };

    const ObserverforPauseEmbed = () => {      
    
     
      // Observer for embedded videos (iframes)
      iframeObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const iframe = entry.target;
            const index = iframe.getAttribute('data-index');
            const videoData = userVideos.value[index];


            if (videoData) {
              if (!entry.isIntersecting && videoData.alreadyIsEmbed) {
                // Pausa o vídeo ao sair do viewport
                if (videoData.currentEmbedURL !== '') {
                  console.log(`Pausing embed at index ${index}`);
                  videoData.currentEmbedURL = ''; // Limpa o URL para pausar o vídeo
                  
                }
              } else if (entry.isIntersecting && !videoData.currentEmbedURL) {
                // Toca o vídeo ao entrar no viewport
                console.log(`Setting embed at index ${index}`);
                videoData.currentEmbedURL = videoData.embedURL;
              }
            } else {
              console.warn(`No videoData found at index ${index}`);
            }
          });
        },
        {
          threshold: [0], // Trigger when any part of the element is visible
        }
      );
      // Observe all embedded video iframes
      document.querySelectorAll('.profile-responsive-video.embed').forEach((iframe) => {
        iframeObserver.observe(iframe);
      });
    };

    // Function to play the embedded video
    const playEmbedVideo = (index) => {
  const video = filteredVideos.value[index];
  if (video) {
    video.embedPlaying = true; // Ativa o embed
    video.currentEmbedURL = video.embedURL; // Define a URL do embed

    // Aguarda o próximo ciclo de atualização do DOM
    nextTick(() => {
      video.alreadyIsEmbed = true;
    });
  } else {
    console.warn(`Vídeo não encontrado no índice ${index}`);
  }
};

    return {
      userVideos,
      thumbsUpCounts,
      toggleThumbsUp,
      toggleThumbsDown,
      isThumbsUpActive,
      playEmbedVideo,
      videoRef,
      activeHashtags,
      userProfilePicture,
      userProfileName,
      filterByHashtag,
      filteredVideos,
      selectedHashtag,
    };
  },
};
</script>

<style>

/* ----------------------AJUSTE CABECALHO---------------------------------- */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-content {
  padding: 0 !important;
  margin: 0;
}

.Base-Div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
}

.user-name-hashtags {
  flex-grow: 1;
}

.profile-user-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.user-round-picture {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
}

.profile-name {
  font-size: 50px;
}

.profile-Hash-container {
  display: flex;
  justify-content: space-between;
  gap: 80px;
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-right: 40px;
  border-radius: 15px  
}

.profile-Hash-border {
  display: flex;
  flex-wrap: wrap;
  padding : 10px;
  padding-top: 32px;
  gap: 10px;
  position: relative;
  border-radius: 15px
}

.profile-Hash-border::before {
  content: 'Filters';
  position: absolute;
  top: -18px; /* Adjust this value to fine-tune the vertical position */
  left: 10px; /* Adjust to add some padding from the left */
  padding: 0 10px;
  background-color: rgb(0, 0, 0); /* Matches the background color to create the gap effect */
  font-size: 28px;
  color: #a90606;
  border-radius: 15px
  
}

.hashtag-item {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.profile-hash-item {
  text-decoration: none;
  background-color: #333;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
}

.profile-hash-item a {
  color: white;
  text-decoration: none;
}

.remove-hashtag-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.remove-hashtag-btn:hover {
  color: red;
}

/* Cursor pointer for hashtag items */
.hashtag-item {
  cursor: pointer;
}

/* Novo CSS para a hashtag selecionada */
.hashtag-item.selected-hashtag .profile-hash-item {
  background-color: #b11919; /* Cor de fundo para a tag selecionada */
  color: white; /* Cor do texto para a tag selecionada */
}

/* ----------------------AJUSTE VIDEOS---------------------------------- */


.video-user-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo (hashtags e vídeo) */
  width: 100%;
}

.video-container {
  position: relative;
  width: 100%; /* Mantém o vídeo com 80% de largura */
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.profile-responsive-video {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80%; /* Limita a largura do vídeo a 80% */
  align-items: center;
  gap: 10px;
  object-fit: cover; 
}

.video-container iframe,
.video-container video {
  width: 100%; /* Garante que o vídeo ou iframe ocupe toda a largura disponível */
  height: 100%; /* Garante que o vídeo ou iframe ocupe toda a altura disponível */
  object-fit: cover; /* Faz o vídeo ocupar todo o espaço do contêiner */
}

.user-buttons-and-tags{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content:left;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 32px;
}

.interaction-buttons {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;  
  max-height: 80%;
}


.thumbs-up,
.thumbs-down {
  display: flex;
  justify-content: flex-start;
  font-size: 2vw;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.thumbs-up-text {
  color: rgb(133, 44, 155);
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 1.5em;
}

.thumbs-down-text {
  color: rgb(77, 77, 77);
  font-weight: bold;
  font-family: 'Verdana', sans-serif;
  font-size: 1.5em;
}

.thumbs-up:active,
.thumbs-down:active {
  background-color: white;
  color: black;
}

.video-hashtags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  width: auto;
  border: 1px solid #ccc; /* Linha fina e cinza */
  box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura */
  flex-grow: 1;
}

.video-hashtags-border {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens ocupem múltiplas linhas */
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
  
}

.video-hashtag-item {
  text-decoration: none;
  background-color: #333;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  
}

.video-hashtag-item a {
  color: white;
  text-decoration: none;
}

.fas.fa-thumbs-up.active {
  color: rgb(0, 124, 182);
}

.fas.fa-thumbs-down.active {
  color: red;
}

/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {


  .user-round-picture {
    width: 75px;
    height: 75px;
  }

  .profile-name {
    font-size: 32px;
  }

  .profile-Hash-border {
    gap: 10px
  }

  .profile-hash-item {
    font-size: 14px;
  }


  .video-user-container {
    width: 100%;
    box-sizing: border-box;
}

  .video-container {
    width: 95%; /* Mantém o vídeo com 80% de largura */
    box-sizing: border-box;

  }

  .profile-responsive-video {
    width: 100%;
    max-width: 100%;
  } 

  .user-buttons-and-tags{
  width: 100%;
  }

  .video-hashtags-container {
    max-width: 100%;
  }

  .video-hashtags-border {
    display: flex;
  }

  .video-hashtag-item{
    font-size: 14px;
  }




}
</style>