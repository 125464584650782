<template>
    <footer class="footer">
      <div class="container">
        <p>Be mindful: what you see cannot be unseen.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'MainFooter',
  }
  </script>
  
  <style scoped>
  .footer {
    background-color: #000000;
    padding: 20px 0;
    text-align: center;
  }
  
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
  </style>
  