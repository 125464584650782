<!-- src/components/Home.vue -->

<template>
  <div>
    <StoriesCategories/>
    <FeedsGallery />
  </div>
</template>

<script>
import StoriesCategories from './StoriesCategories.vue';
import FeedsGallery from './FeedsGallery.vue';


export default {
  name: 'HomePage',
  components: {
    StoriesCategories,
    FeedsGallery,
  }
};
</script>

<style scoped>
html, body, #app {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  color: white; /* Optional: Change the text color to ensure readability */
  display: flex;
  justify-content: center; /* Center content horizontally, optional */
  align-items: center; /* Center content vertically, optional */
}
</style>
