<template>
  <div class="Base-Div">
    <div class="Feeds-macro-container">
      <div
        class="Feeds-user-container"
        v-for="(video, index) in userVideos"
        :key="index"
        :data-embed-url="video.embedURL"
        :data-index="index"
        :href="`#${index}`"
      >
        <div class="user-info">
          <img :src="video.profilePicture" alt="User Picture" class="custom-user-round-picture" />
          <a :href="`https://www.vulcano.com/user/${encodeURIComponent(video.profileName)}`" class="custom-user-profile-name">{{ video.profileName }}</a>
        </div>
        <!-- Preview Video -->
        <video
          v-if="!video.embedPlaying"
          :src="video.previewURL"
          class="responsive-video preview"
          @ended="playEmbedVideo(index)"
          @click="playEmbedVideo(index)"  
          preload="auto"          
          muted          
        ></video>
        
        <!-- Embed Video -->
        <iframe
          v-else
          :src="video.currentEmbedURL"
          frameborder="0"
          allowfullscreen
          class="responsive-video embed"
          :data-index="index" 
          allow="autoplay; encrypted-media"
          sandbox="allow-same-origin allow-scripts"
          @load="removeAds"
          ref="videoIframe"
        ></iframe>
        <div class="buttons-and-tags">
          <div class="feeds-interaction-buttons">
            <button class="custom-thumbs-up" @click="toggleThumbsUp(index)">
              <i :class="{'fas fa-thumbs-up': true, active: isThumbsUpActive(index)}"></i>
            </button>
            <button class="custom-thumbs-down" @click="toggleThumbsDown(index)">
              <i :class="{'fas fa-thumbs-down': true}"></i>
            </button>          
          </div>
          <div class="custom-Hash-container">
            <div class="custom-Hash-border">
              <div class="custom-hash-item" v-for="(hashtag, idx) in video.hashtags" :key="idx">
                <span>{{ hashtag }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // Import axios for making HTTP requests
import { ref, onMounted, nextTick, onUpdated, onUnmounted } from 'vue'; // Import Vue composition API functions

const BASE_URL = 'https://strapi.vulcano2k.com'; // Base URL for API requests

export default {
  name: 'UserProfile', // Component name
  setup() {
    // Define reactive variables using Vue's ref function
    const userVideos = ref([]); // Array to store user videos data
    const thumbsUpCounts = ref([]); // Array to store thumbs up counts for each video
    const videoRef = ref(null); // Reference to video element (currently not used)
    const currentPage = ref(1);
    const pageSize = ref(10);
    const isLoading = ref(false);

    let observer; // Variable for IntersectionObserver for preview videos
    let iframeObserver; // Variable for IntersectionObserver for embedded videos (iframes)

    // Lifecycle hook that runs when the component is mounted
    onMounted(() => {      
      fetchUserData(); // Fetch user data when the component is mounted
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    onUpdated(async () => {
      await nextTick(); // Aguarda a atualização do DOM antes de reconfigurar
      ObserverforPauseEmbed(); // Reconfigura o observer para novos iframes
    });

    // Function to fetch user data from the API
    const fetchUserData = async () => {
        try {
          isLoading.value = true;
          const response = await axios.get(
            `${BASE_URL}/api/user-video-embeds`, {
              params: {
                'pagination[page]': currentPage.value,
                'pagination[pageSize]': pageSize.value,
                populate: {
                  user_profile: {
                    populate: {
                      ProfilePicture: {
                        fields: ['url']
                      }
                    }
                  },
                  hashtags: true,
                  VideoPreview: true
                }
              }
            }
          ); // Make GET request to API endpoint with query parameters to populate user profile, hashtags, and video preview

          const data = response.data.data; // Extract data from response

          // Process the data and store it in userVideos.value
          const newVideos = data.map((item) => ({
            embedURL: getEmbedURL(item),
            profileName: getProfileName(item),
            profilePicture: getProfilePicture(item),
            userId: getUserId(item),
            hashtags: getHashtags(item),
            previewURL: getVideoPreviewURL(item),
            embedPlaying: item.embedPlaying || false,
            currentEmbedURL: '',
            alreadyIsEmbed: false,
          })).sort(() => Math.random() - 0.5);

          // Initialize thumbsUpCounts with zeros for each video
          userVideos.value.push(...newVideos);
          thumbsUpCounts.value = userVideos.value.map(() => 0);

          await nextTick(); // Wait for DOM to update

          setupIntersectionObserver(); // Stop/Start Preview
          

        } catch (error) {
          console.error('Error fetching user data:', error); // Log error if fetching data fails
        } finally {
          isLoading.value = false;
  }
    };

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight || document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !isLoading.value) {
        currentPage.value += 1;
        fetchUserData();
      }
    };





    // Function to get embed URL from item data
    const getEmbedURL = (item) => item.attributes?.embedURL;

    // Function to get video preview URL
    const getVideoPreviewURL = (item) => {
      const previewUrl = item.attributes.VideoPreview.data[0].attributes.url;
      return `${BASE_URL}${previewUrl}`;
    };

    // Function to get profile name from item data
    const getProfileName = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes || 'W/O userProfile associated';
      return userProfile.ProfileName;
    };

    // Function to get profile picture URL
    const getProfilePicture = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes;
      if (userProfile && userProfile.ProfilePicture?.data?.attributes?.url) {
        return `${BASE_URL}${userProfile.ProfilePicture.data.attributes.url}`;
      }
      return ''; // Return an empty string if no profile picture URL is available
    };

    // Function to get user ID from item data
    const getUserId = (item) => item.attributes?.user_profile?.data?.id;

    // Function to get hashtags from item data
    const getHashtags = (item) =>
      item.attributes?.hashtags?.data.map((hashtag) => hashtag.attributes.HashCategorie) || [];

    // Function to toggle thumbs up for a video
    const toggleThumbsUp = (index) => {
      // Toggle between 0 and 1
      thumbsUpCounts.value[index] = thumbsUpCounts.value[index] === 0 ? 1 : 0;
    };

    // Function to check if thumbs up is active for a video
    const isThumbsUpActive = (index) => {
      return thumbsUpCounts.value[index] === 1;
    };

    // Function to handle thumbs down action (remove video from the list)
    const toggleThumbsDown = (index) => {
      const videoContainer = document.querySelectorAll('.Feeds-user-container')[index];

      if (videoContainer) {
        setTimeout(() => {
          // Remove the video data from the arrays
          userVideos.value.splice(index, 1);
          thumbsUpCounts.value.splice(index, 1);

          // Wait for the DOM to update
          nextTick(() => {
            // Disconnect the observers
            if (observer) observer.disconnect();
            if (iframeObserver) iframeObserver.disconnect();

            // Re-observe the current videos
            setupIntersectionObserver();
          });
        }, 100);
      }
    };

    // Function to set up Intersection Observers
    const setupIntersectionObserver = () => {
      // Observer for preview videos
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
            if (entry.isIntersecting) {
              // If the video is in the viewport
              if (video.paused) {                
                video.play(); // Play the video
              }
            } else {
              // If the video is out of the viewport
              video.pause(); // Pause the video
              video.currentTime = 0; // Reset to start
            }
          });
        },
        {
          threshold: [0], // Trigger when any part of the element is visible
        }
      );
      // Observe all preview videos
      document.querySelectorAll('.responsive-video.preview').forEach((video) => {
        observer.observe(video);
      });
    };

    const ObserverforPauseEmbed = () => {      
    
     
      // Observer for embedded videos (iframes)
      iframeObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const iframe = entry.target;
            const index = iframe.getAttribute('data-index');
            const videoData = userVideos.value[index];


            if (videoData) {
              if (!entry.isIntersecting && videoData.alreadyIsEmbed) {
                // Pausa o vídeo ao sair do viewport
                if (videoData.currentEmbedURL !== '') {
                  console.log(`Pausing embed at index ${index}`);
                  videoData.currentEmbedURL = ''; // Limpa o URL para pausar o vídeo
                  
                }
              } else if (entry.isIntersecting && !videoData.currentEmbedURL) {
                // Toca o vídeo ao entrar no viewport
                console.log(`Setting embed at index ${index}`);
                videoData.currentEmbedURL = videoData.embedURL;
              }
            } else {
              console.warn(`No videoData found at index ${index}`);
            }
          });
        },
        {
          threshold: [0], // Trigger when any part of the element is visible
        }
      );
      // Observe all embedded video iframes
      document.querySelectorAll('.responsive-video.embed').forEach((iframe) => {
        iframeObserver.observe(iframe);
      });
    };

    // Function to play the embedded video
    const playEmbedVideo = (index) => {
      userVideos.value[index].embedPlaying = true; // Set embedPlaying to true
      userVideos.value[index].currentEmbedURL = userVideos.value[index].embedURL; // Set current embed URL to start video
      // Re-observe the newly added iframe
      nextTick(() => {
        console.log(`Changing to embed the index ${index}.`)
        userVideos.value[index].alreadyIsEmbed = true;   
      });
    };

    // Return the reactive variables and functions to be used in the template
    return {
      userVideos,
      thumbsUpCounts,
      toggleThumbsUp,
      toggleThumbsDown,
      isThumbsUpActive,
      playEmbedVideo,
      videoRef,
    };
  },
};
</script>



<style>
/* Um reset CSS básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-content {
  padding: 0;
  margin: 0; /* Se houver algum margin também */
}

.Base-Div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;  
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
}

.Feeds-title {
  display: block;
  font-size: 40px;
  align-items: flex-start;
  width: 100%;
  font-family: 'Cinzel', serif;
}

.Feeds-macro-container { 
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  align-items: center;
  gap: 10px;
}

.Feeds-user-container {  /*Video + User Profile + Buttons + tags */
  display: flex;  
  width: 100%;  
  min-width: 200px;
  min-height: 350px;    
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin-top: 14px;
  padding: 5px;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  box-sizing: border-box;
}


.user-info {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 10px;  
  flex-wrap: nowrap; /* Prevents vertical wrapping */
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  max-width: 80%;
}

.custom-user-round-picture {
  align-items: left;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 10px;
}

.custom-user-profile-name {
  font-family: Arial, sans-serif;
  color: #8B0000;
  font-size: 40px;
  text-decoration: none;
}

.responsive-video.preview {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  max-width: 80%;

    
}

.responsive-video.embed {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  max-width: 80%; 
  
}

.buttons-and-tags{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content:left;
  align-items: center;
  padding-top: 18px;

}
.feeds-interaction-buttons {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0;  
  max-height: 80%;
}

.custom-thumbs-up,
.custom-thumbs-down {
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.custom-thumbs-up-text {
  color: rgb(133, 44, 155);  /* Cor para "Sim" */
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
}


.fas.fa-thumbs-up.active {
  color: rgb(0, 124, 182);
}


.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  max-height: 0;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  overflow: hidden;
}


.custom-Hash-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  width: auto;
  border: 1px solid #ccc; /* Linha fina e cinza */
  box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura */
  flex-grow: 1;
}

.custom-Hash-border {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens ocupem múltiplas linhas */
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 10px;
}

  .custom-hash-item{
  text-decoration: none;
  background-color: #333;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  

  }

  .custom-hash-item a {
  color: white;
  text-decoration: none;
}




/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {

  .Feeds-title {
   font-size: 20px;
  }

  .Feeds-user-container {  /*Video + User Profile + Buttons + tags */
  margin-top: 15px;
  padding: 0px;
  }

  .user-info {
   max-width: 100%;
  }

  .custom-user-round-picture {
    width: 45px;
    height: 45px;
  }

  .custom-user-profile-name {
    font-size: 25px;
  }

  .responsive-video.preview {
    max-width: 100%;    
  }

  .responsive-video.embed {
    max-width: 100%;   
  }



.buttons-and-tags{
  width: 100%;
  padding-top: 14px;
}




  .custom-thumbs-up,
  .custom-thumbs-down {
    font-size: 12px;
  }

  .custom-Hash-container {
    max-width: 100%;
  }

  .custom-Hash-container a {
    font-size: 8px;
  }

  .custom-hash-item{
  font-size: 14px;
  }

  .Feeds-macro-container { 
  gap: 0px;
}




.feeds-interaction-buttons {
  max-width: 100%;
}

.custom-hash-item a {
  font-size: 14px;
}

.user-info {
  margin-bottom: 5px;  
}





}
</style>