<template>
  <header class="header">
    <h1>
      <a href="http://localhost:8080" class="custom-site-name">{{siteName}}</a>
    </h1>
    <span class="version">v1.0.0-alpha5</span>
    <!-- Hamburger Menu Icon -->
    <div class="menu-icon" @click="toggleMenu">
        <!-- Simple CSS Hamburger Icon -->
        <div :class="{'bar': true, 'change': menuOpen}"></div>
        <div :class="{'bar': true, 'change': menuOpen}"></div>
        <div :class="{'bar': true, 'change': menuOpen}"></div>
      </div>
  </header>

      <!-- Dropdown Menu -->
      <div v-if="menuOpen" class="dropdown-menu">
      <ul>
        <li @click="navigateTo('/whats-new')">What's New</li>
        <li @click="navigateTo('/copyright')">Copyright</li>
        <li @click="navigateTo('/about-us')">About Us</li>

      </ul>
    </div>

</template>

<script>
export default {
  props: {
    siteName: {
      type: String,
      default: 'Vukcano',
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    navigateTo(route) {
      this.menuOpen = false;
      this.$router.push(route);
    },
  },
};
</script>

<style>

.header h1 {
  margin: 0;
}

.header {
  display: flex;
  justify-content: space-between; /* Distributes space between items */
  align-items: center; /* Aligns items vertically centered */
  background-color: #000000;
  padding: 10px 20px;
  box-sizing: border-box;
}

.custom-site-name {
text-decoration: none;
color: hsl(0, 92%, 20%); /* Cor vinho escura */
margin: 0;
padding: 0;
font-size: 54px;
}

.version {
font-size: 20px;
color: #888;
margin: 0;
padding: 0;
padding-left: 10px;
}

/* Hamburger Menu Styles */
.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 25px;
  margin-left: auto;
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 4px;
  background-color: #ffffff; /* White color for visibility */
  transition: 0.4s;
}

.change {
  background-color: hsl(0, 92%, 50%);
}

/* Dropdown Menu Styles */
.dropdown-menu {
  position: absolute;
  top: 60px; /* Adjust according to header height */
  right: 10px;
  border: 1px solid #cccccc;
  z-index: 1000;
}

.dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: hsl(0, 0%, 0%);
  
}

.dropdown-menu li {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-menu li:hover {
  background-color: #b11919;
}

/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {

  .header {
    padding: 0px;
    padding-right: 10px;
  }

    .custom-site-name {
    font-size: 40px;
  }

  .version {
  font-size: 10px;
  }


}  
</style>
